import i18n from 'i18next'
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        debug: false,
        fallbackLng: "de",
        interpolation: {
            escapeValue: false // react escapes by default
        },
        resources: {
            de: {
                translation: {
                    "error": {
                        404: {
                            "headline": "Seite nicht gefunden",
                            "text": "Die angefragte Seite existiert nicht.",
                            "backLink": "Hier geht zurück zur Startseite",
                        },
                    },
                    "table": {
                        "no-data": "Keine Einträge"
                    },
                    "menu": {
                        "navbar": {
                            "home": {
                                "label": "Start"
                            },
                            "adminSection": {
                                "ariaLabel": "Administrationsbereich"
                            },
                            "membersSection": {
                                "ariaLabel": "Mitgliederverwaltung"
                            },
                            "users": {
                                "label": "Benutzer verwalten"
                            },
                            "members": {
                                "label": "Mitglieder"
                            }
                        },
                        "sidebar": {
                            "admin": {
                                "heading": "Administration",
                                "users": {
                                    "label": "Benutzer verwalten"
                                },
                            },
                            "members": {
                                "heading": "Mitgliederverwaltung",
                                "overview": {
                                    "label": "Mitgliederübersicht"
                                }
                            }
                        },
                        "login": {
                            "ariaLabel": "Führt zum Anmeldebildschirm",
                            "text": "Anmelden"
                        },
                        "myAccount": {
                            "account": {
                                "dropdownLabel": "Mein Nutzerkonto",
                                "ariaLabel": "Link zur Verwaltung meines Nutzerkontos"
                            },
                            "logout": {
                                "dropdownLabel": "Abmelden",
                                "ariaLabel": "Aktuelle Nutzersession beenden"
                            },
                        },
                    },
                    "login": {
                        "headline": "Anmelden",
                        "form": {
                            "username": {
                                "subheading": "Nutzername",
                                "text": "Geben Sie hier den Ihnen bekannten Nutzernamen ein",
                                "ariaLabel": "Ihr Nutzername"
                            },
                            "password": {
                                "subheading": "Passwort",
                                "text": "Geben Sie hier ihr Passwort ein",
                                "ariaLabel": "Ihr Passwort"
                            },
                            "submit": {
                                "label": "Absenden",
                                "ariaLabel": "Anmeldeformular absenden"
                            }
                        },
                        "errors": {
                            "username-empty": "Nutzername darf nicht leer sein.",
                            "password-empty": "Passwort darf nicht leer sein.",
                            "login-failed": "Nutzername oder Passwort sind nicht korrekt.",
                            "unknown-username-issue": "Bitte prüfen Sie Ihre Eingabe für den Nutzernamen.",
                            "unknown-password-issue": "Bitte prüfen Sie Ihre Eingabe für das Password.",
                            "unknown-server-issue": "Login auf Serverseite fehlgeschlagen. Bitte später noch einmal probieren."
                        }
                    },
                    "userList": {
                        "mainHeader": "Benutzerübersicht",
                        "table": {
                            "headers": {
                                "name": "Name",
                                "username": "Nutzername",
                                "role": "Rolle",
                                "active": "Aktiv"
                            },
                            "roleColumn": {
                                "USER": "Nutzer",
                                "ADMIN": "Administrator"
                            },
                            "activeColumn": {
                                "true": "Ja",
                                "false": "Nein"
                            }
                        },
                        "newUserButton": {
                            "ariaLabel": "Neuen Nutzer hinzufügen",
                            "title": "Neuer Nutzer"
                        }
                    },
                    "userForm": {
                        "mainHeader": {
                            "new": "Nutzer anlegen",
                            "edit": "Nutzer editieren"
                        },
                        "fields": {
                            "name": {
                                "subheading": "Name",
                                "text": "Name der nutzenden Person",
                                "placeholder": "Alex Muster",

                            },
                            "username": {
                                "subheading": "Nutzername",
                                "text": "Name, mit dem sich die Person anmeldet",
                                "placeholder": "alex.muster"
                            },
                            "password": {
                                "subheading": "Passwort",
                                "text": "Passwort, mit dem sich die Person authentifiziert.",
                                "additionalText": "Mindestens {{ minLength }} Zeichen",
                                "placeholder": "*******"
                            },
                            "confirmPassword": {
                                "subheading": "Passwort wiederholen",
                                "text": "Bitte Passwort hier noch einmal wiederholen.",
                                "additionalText": "Mindestens {{ minLength }} Zeichen",
                                "placeholder": "*******"
                            },
                            "role": {
                                "subheading": "Benutzerrolle",
                                "text": "\"Nutzer\" dürfen das System normal nutzen. \"Administratoren\" dürfen das System auch anpassen",
                                "options": {
                                    "USER": "Nutzer",
                                    "ADMIN": "Administrator"
                                }
                            },
                            "active": {
                                "subheading": "Nutzer aktiv / inaktiv setzen",
                                "text": "Aktive Nutzer dürfen sich anmelden und das System entsprechend ihrer Rolle benutzen.",
                                "options": {
                                    "USER": "Nutzer",
                                    "ADMIN": "Administrator"
                                }
                            },
                        },
                        "errors": {
                            "invalid-id": "Ungültige ID",
                            "missing-id": 'Fehlende ID. Bitte wenden Sie sich an den Administrator.',
                            "missing-username": 'Nutzername darf nicht leer sein',
                            "username-already-exists": "Der Nutzername wird bereits verwendet.",
                            "password-min-length": "Das Password muss mindestens {{minLength}} Zeichen enthalten.",
                            "missing-password": "Es muss ein Passwort verwendet werden.",
                            "missing-confirm-password": "Bitte Passwort wiederholen.",
                            "password-mismatch": "Passwörter müssen übereinstimmen.",
                            "missing-role": "Bitte wählen Sie eine Rolle aus.",
                            "invalid-role": "Unbekannte Rolle ausgewählt.",
                            "missing-name": "Bitte geben Sie den Namen der benutzenden Person an.",
                            "unknown-id-issue": "Es ist ein unbekannter Fehler mit der Nutzer-ID aufgetreten.",
                            "unknown-name-issue": "Bitte Namen überprüfen.",
                            "unknown-username-issue": "Bitte Nutzernamen überprüfen.",
                            "unknown-password-issue": "Bitte Passwort überprüfen.",
                            "unknown-role-issue": "Bitte Rolle überprüfen.",
                            "unknown-active-issue": "Bitte Eingabe, ob Nutzer aktiv ist überprüfen.",
                            "unknown-server-issue": "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",

                            "section": {
                                "ariaLabel": "Auflistung von Fehlern, die nicht direkt zu Feldern zuordenbar sind"
                            }
                        },
                        "buttons": {
                            "submit": {
                                "ariaLabel": "Formular absenden und Daten speichern",
                                "text": "Speichern"
                            },
                            "cancel": {
                                "ariaLabel": "Abbrechen und zurück zur Übersicht",
                                "text": "Zurück"
                            },
                            "delete": {
                                "ariaLabel": "Nutzer löschen",
                                "text": "Löschen"
                            },
                            "changePassword": {
                                "subheading": "Passwort ändern",
                                "description": "Passwort für Nutzer ändern",
                                "text": "Passwort ändern",
                            }
                        },
                        "dialog": {
                            "delete": {
                                "title": "Nutzer löschen",
                                "description": "Soll der Nutzer {{name}} mit dem Nutzernamen {{username}} wirklich gelöscht werden?"
                            }
                        }

                    },
                    "memberList": {
                        "mainHeader": "Mitgliederverwaltung",
                        "companyPrefix": "Firma",
                        "table": {
                            "headers": {
                                "memberNo": "Mitglieds-Nr.",
                                "name": "Name",
                                "phoneNumbers": "Rufnummern",
                                "address": "Adresse",
                                "status": "Aktiv / Passiv"
                            },
                            "statusColumn": {
                                "ACTIVE": "Aktives Mitglied",
                                "PASSIVE": "Passives Mitglied"
                            },
                            "phoneNumberColumn": {
                                "MOBILE": "Mobil",
                                "HOME": "Privat",
                                "BUSINESS": "Arbeit",
                                "FAX": "Fax",
                            }
                        }
                    },
                    "serverError": {
                        "response-code": "Der Server hat unerwartet mit dem Status {{statusCode}} geantwortet."
                    },
                }
            }
        }

    })

export default i18n;
