import {ProblemDetail} from "@/types/problem-details.ts";

export async function handleResponse<Type>(response: Response) {
    if (response.ok) {
        if (response.headers.get("Content-Type")?.includes("json")) {
            const json: Promise<Type> = await response.json()
            return Promise.resolve(json)
        } else {
            return Promise.resolve()
        }
    } else if (response.status >= 400 && response.status <= 500 &&
        response.headers.get("Content-Type")?.includes("json")) {

        const error: Promise<ProblemDetail> = await response.json()
        return Promise.reject(error)
    } else {
        return Promise.reject({type: "about:blank", "title": "Unknown error", status: response.status})
    }
}
