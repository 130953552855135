import './App.css'
import {Root} from "./components/main-layout/root";
import {UserProvider} from "./context/use-auth";
import './i18n'

export default function App() {

    return (
        <UserProvider>
            <Root/>
        </UserProvider>
    )
}
