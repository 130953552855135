import {Text} from "./tailwind/text.tsx";
import {Link} from "./tailwind/link.tsx";
import {Trans} from "react-i18next";
import {Heading} from "./tailwind/heading.tsx";

export default function Error() {
    return (
        <div className="flex h-screen">
            <div className="m-auto">
                <div className="bg-orange-50 border-l-4 border-orange-500 text-orange-700 p-4 w-max" role="alert">
                    <Heading level={2} className="dark:text-red-950 text-red-950">
                        <Trans i18nKey="error.404.headline"/>
                    </Heading>
                    <Text className="dark:text-black text-black"><Trans i18nKey="error.404.text"/>
                        <Link href="/" role="link" className="underline"><Trans i18nKey="error.404.backLink"/></Link>
                    </Text>
                </div>
            </div>
        </div>
    )
}
