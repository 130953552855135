import {memberColumns} from "./member-columns.tsx"
import {DataTable} from "../../components/data-table.tsx"
import {Heading} from "@/components/tailwind/heading.tsx";
import {Trans} from "react-i18next";
import {useAuth} from "@/context/use-auth.tsx";
import {useEffect, useState} from "react";
import {Member} from "@/models/member.ts"
import {getMembers} from "@/services/member-services.ts";

export default function MemberListPage() {
    const {token} = useAuth()
    const [members, setMembers] = useState<Member[]>([])

    useEffect(() => {
        const membersFromServer = getMembers(token!!)

        membersFromServer.then((members) => setMembers(members as Member[]))
    }, [])

    return (
        <>
            <Heading level={2}><Trans i18nKey="memberList.mainHeader"/></Heading>
            <div className="container mx-auto py-10">
                <DataTable columns={memberColumns} data={members}/>
            </div>
        </>
    )
}
