import {Navigate, useLocation} from "react-router-dom";
import {useAuth} from "../context/use-auth";
import {ReactNode} from "react";

type Props = { children: ReactNode }

export default function ProtectedRoute({children}: Props) {
    const location = useLocation();
    const {isLoggedIn} = useAuth();
    return isLoggedIn() ? (
        <>{children}</>
    ) : (
        <Navigate to="/login" state={{from: location}} replace/>
    )
}
