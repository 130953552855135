import {createBrowserRouter, Outlet} from "react-router-dom";
import App from "../App";
import HomePage from "../pages/home-page";
import LoginPage from "../pages/login-page";
import DashboardPage from "../pages/dashboard-page";
import ProtectedRoute from "./protected-route";
import UsersPage from "../pages/admin/users/users-page.tsx";
import EditUserPage from "../pages/admin/users/edit-user-page.tsx";
import NewUserPage from "../pages/admin/users/new-user-page.tsx";
import Error from "../components/error.tsx";
import MemberListPage from "@/pages/members/member-list-page.tsx";

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {path: "", element: <HomePage/>},
            {path: "login", element: <LoginPage/>},
            {
                path: "dashboard",
                element:
                    <ProtectedRoute>
                        <DashboardPage/>
                    </ProtectedRoute>
            },
            {
                path: "members",
                element: <ProtectedRoute><Outlet/></ProtectedRoute>,
                children: [
                    {
                        path: "",
                        element: <MemberListPage/>
                    }
                ]
            },
            {
                path: "admin",
                element: <ProtectedRoute><Outlet/></ProtectedRoute>,
                children: [
                    {
                        path: "users",
                        element: <UsersPage/>,
                    },
                    {
                        path: "users/new",
                        element: <NewUserPage/>
                    },
                    {
                        path: "users/:userId",
                        element: <EditUserPage/>
                    }

                ]
            },
            {
                path: "*",
                element: <Error/>
            }
        ]
    },

])
