import {handleResponse} from "@/services/service-helpers.ts";
import {Member} from "@/models/member.ts";

const NBH_MEMBERS_BASE_URL = `${import.meta.env.VITE_NBH_URL_ROOT}/api/members`

export async function getMembers(token: string) {
    const response = await fetch(NBH_MEMBERS_BASE_URL, {
        method: 'GET',
        headers: new Headers({
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        })
    });

    return handleResponse<Member[]>(response)

}

