import * as Yup from "yup";
import {Input} from "../components/tailwind/input";
import {Button} from "../components/tailwind/button";
import {useAuth} from "../context/use-auth";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Heading, Subheading} from "../components/tailwind/heading.tsx";
import {useLocation, useNavigate} from "react-router-dom";
import {ProblemDetail} from "../types/problem-details.ts";
import {t} from "i18next";
import ValidationAlert from "../components/validation-alert.tsx";
import {Trans} from "react-i18next";
import {Text} from "../components/tailwind/text.tsx";
import {Divider} from "../components/tailwind/divider.tsx";

type LoginFormsInput = {
    userName: string;
    password: string;
}

const validation = Yup.object().shape({
    userName: Yup.string().required("login.errors.username-empty"),
    password: Yup.string().required("login.errors.password-empty")
});

export default function LoginPage() {

    const navigate = useNavigate()
    const location = useLocation()
    const redirectToAfterLogin = location.state?.from?.pathname || "/dashboard"
    const {loginUser} = useAuth();
    const {
        register,
        handleSubmit,
        formState: {errors},
        setError
    } = useForm<LoginFormsInput>({resolver: yupResolver(validation), mode: "onChange"})

    const handleLogin = (form: LoginFormsInput) => {
        const login = loginUser(form.userName, form.password)

        login
            .then((_) => {
                navigate(redirectToAfterLogin)
            })
            .catch((reason: ProblemDetail) => {
                if (reason.validationIssues) {
                    reason.validationIssues.map(
                        finding => {
                            setError(
                                finding.field as "userName" | "password" | `root.${string}` | "root",
                                {
                                    message: finding.message
                                }
                            )
                        }
                    )
                } else if (reason.status === 401) {
                    setError("root", {
                        type: "401",
                        message: t("login.errors.login-failed")
                    })
                    setError("userName", {})
                    setError("password", {})
                } else {
                    setError("root", {
                        type: `${reason.status}`,
                        message: t("serverError.response-code", {statusCode: reason.status})
                    })
                }
            })
    }

    return (
        <div>
            <form onSubmit={handleSubmit(handleLogin)} className="mx-auto max-w-4xl">
                <Heading level={2}><Trans i18nKey="login.headline"/></Heading>

                <Divider className="my-10 mt-6"/>

                {errors.root && (
                    <section role="alert">
                        <ValidationAlert message={errors.root.message || t("login.errors.unknown-server-issue")}/>
                    </section>
                )}

                <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div className="space-y-1">
                        <Subheading><Trans i18nKey="login.form.username.subheading"/>:</Subheading>
                        <Text><Trans i18nKey="login.form.username.text"/></Text>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <Input
                            {...register("userName")}
                            type="text"
                            id="username"
                            invalid={!!errors.userName}
                            aria-label={t("login.form.username.ariaLabel")}
                            required
                        />

                        {errors.userName && errors.userName.message && (
                            <ValidationAlert message={t(errors.userName.message || "login.errors.unknown-username-issue")}/>
                        )}
                    </div>
                </section>

                <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2 mt-4">
                    <div className="space-y-1">
                        <Subheading><Trans i18nKey="login.form.password.subheading"/>:</Subheading>
                        <Text><Trans i18nKey="login.form.password.text"/></Text>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <Input
                            type="password"
                            id="password"
                            invalid={!!errors.password}
                            required
                            {...register("password")}
                            aria-label={t("login.form.password.ariaLabel")}
                        />


                        {errors.password && errors.password.message && (
                            <ValidationAlert message={t(errors.password.message || "login.errors.unknown-password-issue")}/>
                        )}
                    </div>
                </section>

                <section className="flex justify-end gap-4 mt-6">
                    <Button type="submit" aria-label={t("login.form.submit.ariaLabel")}>
                        <Trans i18nKey="login.form.submit.label"/>
                    </Button>
                </section>

            </form>
        </div>
    )
}
