import {useAuth} from "@/context/use-auth.tsx";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button} from "@/components/tailwind/button.tsx";
import {Heading} from "@/components/tailwind/heading.tsx";
import {Trans} from "react-i18next";
import {t} from "i18next";
import {DataTable} from "@/components/data-table.tsx";
import {columns} from "@/pages/admin/users/users-columns.tsx";
import {Divider} from "@/components/tailwind/divider.tsx";
import {getUsers} from "@/services/user-services.ts";

type UserAccount = {
    id: string,
    username: string,
    name: string,
    active: boolean,
    role: "ADMIN" | "USER",
    createdAt: Date,
    updatedAt: Date | null
}


export default function UsersPage() {
    const {token, isAdmin} = useAuth()
    const [users, setUsers] = useState<UserAccount[]>([])

    const navigate = useNavigate()

    if (!isAdmin) {
        navigate("/dashboard")
    }

    useEffect(() => {
        const usersFromServer = getUsers(token!!)

        usersFromServer.then((users) => setUsers(users as UserAccount[]))
    }, [])

    return (
        <>
            <Heading><Trans i18nKey="userList.mainHeader"/></Heading>

            <DataTable columns={columns} data={users}/>

            <Divider className="my-5" soft/>

            <section className="flex justify-end gap-4">
                <Button aria-label={t("userList.newUserButton.ariaLabel")} href="/admin/users/new">
                    <Trans i18nKey="userList.newUserButton.title"/>
                </Button>
            </section>
        </>
    )
}
