import {ColumnDef} from "@tanstack/react-table";
import {sortableColumnHeader} from "@/components/data-table.tsx";
import {t} from "i18next";
import {Trans} from "react-i18next";
import {Link} from "@/components/tailwind/link.tsx";
import {Text} from "@/components/tailwind/text"
import {EditIcon} from "lucide-react";
import {Member, PhoneNumber} from "@/models/member.ts";

function generateNameColumnContent(row: Member): string {
    if (row.companyName) {
        return t('memberList.companyPrefix') + ` ${row.companyName}`
    } else {
        return [row.salutation, row.title, row.firstName, row.lastName].filter(Boolean).join(" ")
    }
}


function generatePhoneColumnContent(phoneNumbers?: PhoneNumber[]) {
    if (!Array.isArray(phoneNumbers) || !phoneNumbers?.length) {
        return (<></>)
    }

    return (
        <section role="list">
            {phoneNumbers.map((entry, index) => (
                <div role="listitem" className={"flex"} key={index}>
                        <Text><Trans i18nKey={`memberList.table.phoneNumberColumn.${entry.type}`}/>: </Text>
                        <Link href={`tel:${entry.number}`}>
                            <Text>{entry.number}</Text></Link>
                    </div>
                )
            )}
        </section>
    )
}

export const memberColumns: ColumnDef<Member>[] = [
    {
        accessorKey: "memberNo",
        header: ({column}) =>
            sortableColumnHeader(column, "memberList.table.headers.memberNo")
    },
    {
        id: "name",
        accessorFn: generateNameColumnContent,
        header: ({column}) =>
            sortableColumnHeader(column, "memberList.table.headers.name")

    },
    {
        id: "phoneNumbers",
        header: t("memberList.table.headers.phoneNumbers"),
        cell: ({row}) => generatePhoneColumnContent(row.original.phoneNumbers)
    },
    {
        accessorKey: "address",
        header: t("memberList.table.headers.address"),
        cell: ({row}) => {
            if (row.original.address) {
                return (
                    <address>
                        <Text>{row.original.address?.street} {row.original.address?.houseNumber}</Text>
                        <Text>{row.original.address?.postcode} {row.original.address?.city}</Text>
                    </address>
                )
            }
        }

    },
    {
        accessorKey: "status",
        header:
            ({column}) =>
                sortableColumnHeader(column, "memberList.table.headers.status"),
        cell:
            ({row}) => {
                return <Trans i18nKey={"memberList.table.statusColumn." + (row.getValue("status") || "PASSIVE")}/>
            }
    },
    {
        id: "actions",
        cell: ({row}) => {
            const member = row.original

            return (
                <Link href={`/members/${member.id}`}><EditIcon/></Link>

            )
        }

    }
]
