import {UserProfile} from "../models/user.ts";
import {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {jwtDecode, JwtPayload} from "jwt-decode";
import {ProblemDetail} from "../types/problem-details.ts";

type UserContextType = {
    user: UserProfile | null;
    token: string | null;
    loginUser: (username: string, password: string) => Promise<void | ProblemDetail>;
    logout: () => void;
    isLoggedIn: () => boolean;
    isAdmin: () => boolean;
};
type LoginResponseType = {
    accessToken: string,
    role: "USER" | "ADMIN"
}


type Props = { children: ReactNode };

const UserContext = createContext<UserContextType>({} as UserContextType);

export const UserProvider = ({children}: Props) => {
    const navigate = useNavigate();
    const [token, setToken] = useState<string | null>(null);
    const [user, setUser] = useState<UserProfile | null>(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem("user");
        const token = localStorage.getItem("token");

        if (user && token && tokenIsStillValid(token)) {
            setUser(JSON.parse(user));
            setToken(token);
        }
        setIsReady(true);
    }, []);

    const tokenIsStillValid = (token: string): boolean => {
        const {exp} = jwtDecode<JwtPayload>(token);
        return (Date.now() < exp! * 1000)
    }

    async function loginUser(username: string, password: string): Promise<void | ProblemDetail> {
        const response = await fetch(`${import.meta.env.VITE_NBH_API_ROOT}/auth/login`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({'username': username, 'password': password})
        })

        if (response.ok) {
            const tokenResponse = await response.json()
            const actualToken = (tokenResponse as LoginResponseType).accessToken;

            const decoded = jwtDecode<JwtPayload>(actualToken);
            const userObj: UserProfile = {
                userName: decoded.sub!,
                role: (tokenResponse as LoginResponseType).role
            }

            localStorage.setItem("token", actualToken);
            localStorage.setItem("user", JSON.stringify(userObj));

            setToken(actualToken);
            setUser(userObj);

            return Promise.resolve()
        } else if (response.headers.get("Content-Type")?.includes("json")) {
            const error: Promise<ProblemDetail> = await response.json()
            return Promise.reject(error)
        } else {
            return Promise.reject({type: "about:blank", "title": "Unknown error", status: response.status})
        }

    }

    const isLoggedIn = () => {
        return !!user;
    }

    const isAdmin = () => {
        return !!user && user.role === "ADMIN"
    }

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setUser(null);
        setToken("");
        navigate("/");
    }

    return (
        <UserContext.Provider value={{loginUser, user, token, logout, isLoggedIn, isAdmin}}>
            {isReady ? children : null}
        </UserContext.Provider>
    );
}

export const useAuth = () => useContext(UserContext)
