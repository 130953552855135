import {ColumnDef} from "@tanstack/react-table";
import {EditIcon} from "lucide-react";
import {Trans} from "react-i18next";
import {Link} from "@/components/tailwind/link.tsx";
import {sortableColumnHeader} from "@/components/data-table.tsx";

export type UserAccount = {
    id: string,
    username: string,
    name: string,
    active: boolean,
    role: "ADMIN" | "USER",
    createdAt: Date,
    updatedAt: Date | null
}

export const columns: ColumnDef<UserAccount>[] = [
    {
        accessorKey: "name",
        header: ({column}) =>
            sortableColumnHeader(column, "userList.table.headers.name")

    },
    {
        accessorKey: "username",
        header: ({column}) =>
            sortableColumnHeader(column, "userList.table.headers.username")

    },
    {
        accessorKey: "role",
        header: ({column}) =>
            sortableColumnHeader(column, "userList.table.headers.role"),
        cell: ({row}) => {
            return (
                <Trans i18nKey={`userList.table.roleColumn.${row.original.role}`}/>
            )
        }
    },
    {
        accessorKey: "active",
        header: ({column}) =>
            sortableColumnHeader(column, "userList.table.headers.active"),
        cell: ({row}) => {
            return (
                <Trans i18nKey={`userList.table.activeColumn.${row.original.active}`}/>
            )
        }
    },
    {
        id: "actions",
        cell: ({row}) => {
            const user = row.original

            return (
                <Link href={`/admin/users/${user.id}`}><EditIcon/></Link>

            )
        }

    }

]
